import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MemoryService } from 'src/app/services/memory.service';
import { DialogSupportComponent } from '../dialogs/dialog-support/dialog-support.component';
import { config } from 'src/config';
import { InspectorService } from 'src/app/views/inspector/tasks/inspector.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/services/user.service';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { TranslationBackendService } from 'src/app/services/translation-file.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    RouterModule,
    UserAvatarComponent
  ]
})
export class HeaderComponent implements OnInit {
  @Input() isMobile = false;
  @Input() navbarOpen = true;
  @Output() navbarToggled = new EventEmitter<boolean>();

  languages: { label: string; value: string }[];
  availableLanguages: string[];
  roleId: number;

  openTasks: number;
  isLoggedIn = false;

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private memory: MemoryService,
    private readonly inspectorService: InspectorService,
    private readonly userService: UserService,
    private readonly translocoService: TranslocoService,
    private readonly translateService: TranslationBackendService
  ) {
    this.cd.detach();
    this.availableLanguages = this.translocoService.getAvailableLangs() as string[];
    this.languages = [];
    for (const lang of this.availableLanguages) {
      this.languages.push({ label: config.languages[lang], value: lang });
    }
  }

  ngOnInit(): void {
    window.setTimeout(() => {
      this.cd.detectChanges();
      this.cd.reattach();
    }, 500);

    this.memory.$userLoginData.subscribe((userData) => {
      if (userData) {
        this.roleId = userData.roleId;
        this.isLoggedIn = true;
        if (this.roleId === 6) {
          this.userService
            .getUserNotifications(true, { subscription: { $substring: 'follow-up' } })
            .subscribe((data) => {
              this.openTasks = data.count;
            });
          this.inspectorService.openTasks$.subscribe((tasks) => {
            this.openTasks = tasks;
          });
        } else if (this.roleId === 10) {
          this.userService
            .getUserNotifications(false, {
              subscription: {
                $or: [
                  { $substring: '%missing%' },
                  { $substring: '%defect%' },
                  { $substring: '%notFitting%' },
                  { $substring: '%price-raise%' },
                  { $substring: '%base-item%' }
                ]
              },
              userId: userData.userId
            })
            .subscribe((data) => {
              this.openTasks = data.count;
              this.inspectorService.openTasks$.subscribe((tasks) => {
                this.openTasks = tasks;
              });
            });
        } else {
          this.openTasks = null;
        }
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  reload() {
    window.location.reload();
  }

  emptyCart() {
    localStorage.removeItem('cartItems');
    this.reload();
  }

  onSupportClicked(): void {
    this.dialog.open(DialogSupportComponent, {
      width: '90%',
      maxWidth: '500px',
      data: {}
    });
  }

  useLanguage(id: string): void {
    this.memory.changeLanguage(id);
  }

  translateNow() {
    this.translateService.translateAutomatically().subscribe((e) => console.log(e));
  }
}
