export const environment = {
  production: true,
  //   apiUrl: 'https://stagingapi.deymann.app/',
  apiUrl: 'http://localhost:3002/',
  frontentUrl: 'https://staging.deymann.app/',
  languages: { de: 'Deutsch', en: 'English', nl: 'Nederlands', pl: 'Polský', cs: 'Česky' },
  environment: 'staging-deymann',
  developerEmails: ['info@complink.app', 'hans.voelzer@com-and.de'],
  googleMapAPI: 'AIzaSyAZyZbCwEXXKrmDeejMwpMt4VvHmP-BMDk',
  appTitle: 'DEYMANN.APP',
  appVersion: require('../../package.json').version + '-stg'
};
