import { StepDefinition } from 'src/app/interfaces/process/step-definition.interface';
import { Step } from 'src/app/interfaces/process/step.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { ProcessService } from 'src/app/services/process.service';
import { AlreadyRunningDialogComponent } from '../../dialogs/already-running-dialog/already-running.component';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslationBackendService } from 'src/app/services/translation-file.service';
import { TranslocoService } from '@ngneat/transloco';
import { IProcessStartOptions } from 'src/app/interfaces/process/process.interface';
import {
  ProcessHandlerDialogComponent,
  AllStepsPerformedEvent
} from 'src/app/process-engine/process/process-handler-dialog/process-handler-dialog.component';
import { DatePipe } from '@angular/common';
import { MemoryService } from 'src/app/services/memory.service';
import { DataFieldTranslation } from 'src/app/interfaces/process/data-field.interface';
import { PredefinedDataField } from './new-data.interface';
import { SiteModel } from 'src/app/models/site.model';

@Injectable()
export class StartProcessService {
  alreadyRunningDialogClosedTrigger$ = new Subject<boolean>();
  processHandlerDialogClosedTrigger$ = new Subject<{ actionPerformed?: boolean; processFinished?: boolean }>();
  siteName: string;
  siteId: number;
  predefinedDataFields: any[] = [];
  noTabs: boolean;

  constructor(
    private readonly processService: ProcessService,
    private readonly processHandlerService: ProcessHandlerService,
    private readonly notification: NotificationService,
    private readonly translateBackendService: TranslationBackendService,
    private readonly translate: TranslocoService,
    private readonly datePipe: DatePipe,
    private dialog: MatDialog,
    private readonly memoryService: MemoryService
  ) {}

  resumeProcess(step: Step, noTabs: boolean) {
    this.noTabs = noTabs;
    this.openProcessHandlerDialog(step, []);
  }

  startProcess(
    processDefinitionId: number,
    responsibleCompany: number,
    idCode1: string,
    processPhase: string,
    alreadyRunningSteps: Step[],
    predefinedDataFields: any[] = [],
    noTabs?: boolean
  ) {
    this.siteName = idCode1;
    this.siteId = responsibleCompany;
    this.noTabs = noTabs;
    this.predefinedDataFields = predefinedDataFields;

    // here we need to translate all the datafields that needs to have an initial value in the start step.
    // these values will be set in setInitialValues() in process-handler-component.ts
    const barge = this.translateBackendService.getPETranslationByKey(
      'Barge/Vessel',
      '',
      this.translate.getActiveLang()
    );
    const visitor = this.translateBackendService.getPETranslationByKey('Visitor', '', this.translate.getActiveLang());
    forkJoin([visitor, barge]).subscribe((response) => {
      const visitorTranslation = response[0];
      const bargeTranslation = response[1];

      const translations: DataFieldTranslation[] = [
        { name: 'Visitor', value: visitorTranslation },
        { name: 'Barge/Vessel', value: bargeTranslation }
      ];

      this.processService.getStartStepDefinition(processDefinitionId).subscribe(
        (startStep) => {
          const running = alreadyRunningSteps
            .filter((step) => step.process.process_definition_id === processDefinitionId)
            .filter((step) => step.process.responsible_company === responsibleCompany)
            .map((e) => {
              const visitorDf = e.process.DataFields.find((field) => field.name === visitorTranslation)?.value;
              let own: boolean;
              if (Number(visitorDf) === this.memoryService.getLoginData().userId) {
                own = true;
              }
              return { ...e, visitor: visitorDf, canResume: own };
            });
          if (running.length) {
            // if there's an already running step with this definition, we take this instead of creating a new process
            this.openAlreadyRunningDialog(running, startStep, translations, processPhase);
          } else {
            this.openProcessHandlerDialog(ProcessService.stepDefinitionToFakeStep(startStep) as Step, translations);
          }
        },
        (error) => {
          this.notification.error(error.message || JSON.stringify(error));
        }
      );
    });
  }

  /**
   *
   * @param processDefinitionId
   * @param site optional for some definitionids
   * @returns
   */
  generatePredefinedDatafieldsByDefinitionId(processDefinitionId: number, site?: SiteModel): PredefinedDataField[] {
    let predefinedDataFields: PredefinedDataField[] = [];
    switch (processDefinitionId) {
      case 6:
        predefinedDataFields = [
          { name: 'Visitor', value: `${this.memoryService.getLoginData().userId}` },
          { name: 'Barge/Vessel', value: `${site.name}` }
        ];
        break;
      case 7:
        const dateTime = new Date();
        predefinedDataFields = [
          { name: 'Visitor', value: `${this.memoryService.getLoginData().userId}` },
          { name: 'Barge/Vessel', value: `${site.name}` },
          { name: 'Datum/Zeit', value: dateTime },
          { name: 'AIS Position', value: { lat: site.latitude, lng: site.longitude } },
          { name: 'AIS Received', value: site.locationMeta.find((lm) => lm.key === 'position.received').value }
          // { name: 'Telefon', value: use site.mainAsset and include assetattrvalues }
        ];
        break;
      case 16:
        predefinedDataFields = [{ name: 'Visitor', value: `${this.memoryService.getLoginData().userId}` }];
        break;
    }

    return predefinedDataFields;
  }

  private openAlreadyRunningDialog(
    alreadyRunningSteps: Step[],
    startStepDefinition: StepDefinition<any>,
    translations: DataFieldTranslation[],
    processPhase: string
  ) {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    const dialogRef = this.dialog.open<AlreadyRunningDialogComponent>(AlreadyRunningDialogComponent, {
      ...dimensions,
      data: {
        alreadyRunningSteps,
        translations,
        processPhase
      }
    });

    dialogRef.afterClosed().subscribe((event) => {
      if (event?.startNew) {
        this.openProcessHandlerDialog(
          ProcessService.stepDefinitionToFakeStep(startStepDefinition) as Step,
          translations
        );
      } else if (event?.actionPerformed) {
        this.alreadyRunningDialogClosedTrigger$.next(true);
      } else {
        this.alreadyRunningDialogClosedTrigger$.next(false);
      }
    });
  }

  private openProcessHandlerDialog(step: Step, translations: DataFieldTranslation[]): void {
    const dimensions = this.processHandlerService.getDialogScreenSize();

    const dialogRef = this.dialog.open<ProcessHandlerDialogComponent>(ProcessHandlerDialogComponent, {
      ...dimensions,
      data: {
        steps: [step],
        processStartOptions: {
          predefined_attributes: {
            id_code_1: this.siteName,
            id_code_2: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
            responsible_company: this.siteId
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          predefined_DataField_values: [...this.predefinedDataFields]
        } as IProcessStartOptions,
        noTabs: this.noTabs,
        translations
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // ToDo: this doesnt make sense the last trigger is always called. Why??
      if (result instanceof AllStepsPerformedEvent) {
        // update processes in history view if needed
        this.processHandlerDialogClosedTrigger$.next({ processFinished: true });
      } else if (result.actionPerformed) {
        this.processHandlerDialogClosedTrigger$.next({ actionPerformed: true });
      } else {
        this.processHandlerDialogClosedTrigger$.next(undefined);
      }
      this.siteId = null;
    });
  }
}
